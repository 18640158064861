import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { CardCTA, HeaderFilter, SectionPosts, Text } from "components";
import { Layout } from "containers";
import { getPageFilters } from "utils/page";

const PageBlog = ({
  data: {
    config: { header, seo },
    imageCategories,
    postCategories,
    posts,
    site: {
      siteMetadata: { subscribe },
    },
  },
  location,
}) => {
  const filters = getPageFilters(postCategories, "/blog");
  const hasPosts = posts?.edges?.length > 0;

  const layoutProps = {
    seoProps: seo,
  };

  const heroProps = {
    filters,
    heading: "Blog",
    location,
    ...header,
  };

  const sectionPostsProps = {
    className: !hasPosts ? "cta" : null,
    containerProps: !hasPosts && {
      className: "cta",
      maxWidth: "md",
    },
    posts,
  };

  const cardCTAProps = {
    button: {
      href: subscribe,
      label: "Subscribe",
      target: "_blank",
    },
    className: "featured",
    content: (
      <>
        <Text>
          Hey there, fellow creatives! We're working with the coolest remote
          companies to find you the coolest jobs.
        </Text>
        <Text>
          Subscribe below to get those right to your inbox. No spam, ever. Your
          email's safe with us.
        </Text>
      </>
    ),
    heading: "Subscribe for remote gigs!",
    image: {
      className: "featured",
      ...imageCategories,
    },
  };

  return (
    <Layout {...layoutProps}>
      {hasPosts && <HeaderFilter {...heroProps} />}
      <SectionPosts {...sectionPostsProps}>
        {!hasPosts && <CardCTA {...cardCTAProps} />}
      </SectionPosts>
    </Layout>
  );
};

PageBlog.propTypes = {
  data: PropTypes.shape({
    config: PropTypes.shape({
      header: PropTypes.shape({
        heading: PropTypes.string,
      }),
      id: PropTypes.string,
      seo: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
    imageCategories: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          base64: PropTypes.string,
          aspectRatio: PropTypes.number,
          src: PropTypes.string,
          srcSet: PropTypes.string,
          sizes: PropTypes.string,
        }),
      }),
      name: PropTypes.string,
    }),
    postCategories: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          category: PropTypes.oneOf(["Books", "Design", "Interview"]),
        })
      ),
    }),
    posts: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            data: PropTypes.shape({
              article: PropTypes.oneOfType([
                PropTypes.shape({
                  childMdx: PropTypes.shape({
                    excerpt: PropTypes.string,
                  }),
                }),
                PropTypes.string,
              ]),
              author: PropTypes.string,
              category: PropTypes.oneOf(["Books", "Design", "Interview"]),
              date: PropTypes.string,
              description: PropTypes.string,
              id: PropTypes.string,
              showComments: PropTypes.bool,
              title: PropTypes.string,
              updateDate: PropTypes.string,
            }),
            id: PropTypes.string,
          }),
        })
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        subscribe: PropTypes.string,
      }),
    }),
  }),
};

export const blogPageQuery = graphql`
  query BlogPageQuery {
    config: pagesYaml(id: { eq: "PageBlog" }) {
      header {
        heading
      }
      id
      seo {
        title
      }
    }
    imageCategories: file(name: { eq: "mascot-categories" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90, traceSVG: { color: "#ced0d4" }) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      name
    }
    postCategories: allAirtable(
      filter: { data: { isActive: { eq: true } }, table: { eq: "Posts" } }
    ) {
      group(field: data___category) {
        category: fieldValue
      }
    }
    posts: allAirtable(
      filter: { data: { isActive: { eq: true } }, table: { eq: "Posts" } }
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          data {
            article {
              childMdx {
                excerpt(pruneLength: 400)
              }
            }
            author
            category
            date(formatString: "MMMM DD, YYYY")
            description
            id
            # showComments
            title
            updateDate(formatString: "MMMM DD, YYYY")
          }
          id
        }
      }
    }
    site {
      siteMetadata {
        subscribe
      }
    }
  }
`;

export default PageBlog;
